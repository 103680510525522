<template>
    <div>
        <b-modal id="modal-view-update-expire-bonus-detail" cancel-variant="outline-secondary" ok-title="Ok"
            cancel-title="Close" ok-variant="primary" centered size="lg" title="Update Status Bonus Request"
            @ok="confirmUpdate" @hidden="resetModal()">
            <validation-observer ref="editUserBonus">
                <b-form ref="" method="POST" class="auth-reset-password-form mt-2" @submit.prevent="validationForm">
                    <b-col cols="12">
                        <b-form-group label-for="is_expired">
                            <label class="mb-1 d-block">{{ $t("Hết hạn") }} <span class="text-danger">*</span></label>
                            <validation-provider #default="{ errors }" name="is_expired" rules="required">
                                <b-form-radio v-model="bonusData.is_expired" name="true" value="1" inline>
                                    True
                                </b-form-radio>
                                <b-form-radio v-model="bonusData.is_expired" name="false" value="2" inline>
                                    False
                                </b-form-radio>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                        <b-form-group label-for="status">
                            <label class="mb-1 d-block">Rollover <span class="text-danger">*</span></label>
                            <b-form-input id="rollover" v-model="bonusData.rollover" type="number" />
                        </b-form-group>
                    </b-col>
                </b-form>
            </validation-observer>
        </b-modal>
    </div>
</template>
  
<script>
import {
    ValidationProvider,
    ValidationObserver,
} from "vee-validate/dist/vee-validate.full.esm";
import {
    BOverlay,
    BImg,
    BFormSpinbutton,
    BFormRadio,
    BLink,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    BInputGroup,
    BInputGroupPrepend,
    BModal,
    VBModal,
    BInputGroupAppend,
    BBadge,
    BFormFile,
} from "bootstrap-vue";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent";

export default {
    components: {
        BOverlay,
        BImg,
        BFormSpinbutton,
        BFormRadio,
        BLink,
        BRow,
        BCol,
        BForm,
        BFormGroup,
        BFormInput,
        BButton,
        BInputGroup,
        BInputGroupPrepend,
        BModal,
        BBadge,
        BFormFile,
        BInputGroupAppend,
        ValidationProvider,
        ValidationObserver,
        vSelect,
    },
    directives: {
        "b-modal": VBModal,
        Ripple,
    },
    props: {
        bonusDetail: {
            type: Object,
            default: null,
        },
    },
    data() {
        return {
            bonusData: {
                id: "",
                is_expired: "",
                rollover: ""
            },
        };
    },
    watch: {
        bonusDetail: {
            handler(newVal) {
                if (newVal) {
                    this.bonusData = {
                        id: newVal.id,
                        is_expired: newVal.is_expired,
                        rollover: newVal.rollover
                    };
                }
            },
            deep: true,
        },
    },
    methods: {
        resetModal() {
            this.$emit("update:bonusDetail", null);
        },
        confirmUpdate(bvModalEvt) {
            bvModalEvt.preventDefault();
            this.validationForm();
        },
        validationForm() {
            this.$refs.editUserBonus.validate().then((success) => {
                if (success) {
                    this.updateExpireBonus(this.bonusData);
                }
            });
        },
        async updateExpireBonus(bonusDetail) {
            await this.$store
                .dispatch("bonus/updateBonus", bonusDetail)
                .then((response) => {
                    if (response.status === 200) {
                        if (response.data.code === "00") {
                            this.$toast({
                                component: ToastificationContent,
                                props: {
                                    title: response.data.message || "success",
                                    icon: "CheckIcon",
                                    variant: "success",
                                },
                            });
                            this.$nextTick(() => {
                                setTimeout(() => {
                                    this.$bvModal.hide("modal-view-update-expire-bonus-detail");
                                }, "500");
                            });
                            this.$emit("refetch-data");
                        } else {
                            this.$toast({
                                component: ToastificationContent,
                                props: {
                                    title: response.data.message,
                                    icon: "AlertTriangleIcon",
                                    variant: "danger",
                                },
                            });
                        }
                    }
                });
        },
    },
    setup() { },
};
</script>
<style lang="scss">
// @import '@core/scss/vue/libs/quill.scss';
</style>
  